//import logo from './logo.svg';
import React, { useState } from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
import './App.css';
import SpinWheel from './SpinWheel.js'
//import 'react-wheel-of-prizes/dist/index.css'
//import styles from "assets/jss/material-kit-react/views/landingPage.js";

const dashboardRoutes = [];

//const useStyles = makeStyles(styles);

export default function WheelPage(props) {
  //const classes = useStyles();
  const { ...rest } = props;

  /*const segments = [
    'better luck next time',
    'won 70',
    'won 10',
    'you won a chance',
    'won 2',
    'won uber pass',
    'movie ticket',
    'won a voucher'
  ]
  const segColors = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
    '#EC3F3F',
    '#FF9000'
  ]*/

  const segments = [
    'Vontron Pen',
    'better luck next time',
    'Vontron Pen',
    'Suprise Gift',
    'Vontron Pen'
  ]
  const segColors = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F'
  ]

  const [finishedText, setText] = useState("");
  const [spinFinished, setSpinStatus] = useState(false);
  const name = window.sessionStorage.getItem("name");
  const ticketNo = window.sessionStorage.getItem("ticketNo");

  const onFinished = (winner) => {
    setText("Congratulations you have won a '" + winner + "' prize");
    setSpinStatus(true);
    let formdata = {"prize":winner}
    // Update Prize Column
    fetch('https://api.110techost.com/index.php/users/'+ticketNo, {
        method: 'PUT', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formdata),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data[0].status);
      })
      .catch((error) => {
        console.error('Error:', error);
      }); 


  }

  return (
    <div>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("assets/img/vontron.png").default} />}
        rightLinks=""
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div style={{'margin-top':'80px'}}>
        <h3 style={{'background':'white','padding':'70px 15px 20px 15px'}}>Welcome {name}! Your lucky draw number is : {ticketNo}</h3>
        { !spinFinished ?
          <SpinWheel
            segments={segments}
            segColors={segColors}
            winningSegment='Vontron Pen'
            onFinished={(winner) => onFinished(winner)}
            primaryColor='black'
            contrastColor='white'
            buttonText='Spin'
            isOnlyOnce={true}
            size={190}
            upDuration={500}
            downDuration={1000}
            fontFamily='Arial'
          />
          : <img src={require("assets/img/68952-blast-new.gif").default} alt="Congratulations" style={{'maxWidth':'100%'}} />
          }
          { spinFinished ?
            <h3 style={{'text-align':'center','background':'#4182CC','margin-top':'0','padding':'15px','color':'white'}}>{ finishedText }</h3>
            : ""
          }
        </div>
      <Footer />
    </div>
  );
}
